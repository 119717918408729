.sponsors-container{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sponsor-image{
    margin: 50px;
    max-width: 180px;
    max-height: 250px;
}