.events__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.events__title {
    color: #ffffff;
    font-family: "Orbitron", sans-serif;
}

.container {
    text-align: center;
}