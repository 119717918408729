@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Rubik&display=swap);
.navbar{
    width: 100%;
    min-height: 2rem;
    opacity: 0.9;
    position: relative;
    z-index: 1;
}

.navbar__logo{
    max-width: 200px;
    display: inline-block;
    padding: 0.4rem 1rem 0;
}

.navbar__menu{
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    position: fixed;
    left: -100%;
    top: 5%;
    list-style-type: none;
    font-size: 2rem;
    transition: all 0.5s ease-out;
    text-align: center;
    opacity: 0.75;
    z-index: 1;
}

.navbar__menu--active{
    background-color: #004D69;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    position: fixed;
    left: 0;
    top: 5%;
    list-style-type: none;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    transition: all 0.5s ease-out;
    text-align: center;
    opacity: 1;
    z-index: 1;
}

.menu-icon{
    color: #fff;
    position: absolute;
    right: 2rem;
    top: 2rem;
}

@media screen and (min-width: 768px){

    .navbar__menu{
        flex-direction: row;
        justify-content: flex-end;
        font-size: 1.1rem;
        font-weight: 550;
        height: auto;
        left: auto;
        left: initial;
        max-width: 500px;
        opacity: 1;
        top: 0;
        right: 0;
        position: absolute;
    }

    .navbar__menu, li{
        padding: 7px 5px 0;
        font-weight: 700;
    }

    .menu-icon{
        display: none;
    }

    .navbar__menu__link:hover{
        background-image: linear-gradient(180deg, rgb(0, 0, 0, 0.3), rgb(255, 0, 0, 0));
    }
}
.footer{
    bottom: 0;
    color: #fff;
    opacity: 0.9;
    left: 0;
    position: absolute;
    right: 0;
}

.footer__container{
    align-items: flex-start;
    background-color: #0C2948;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    width: 100%;
    padding-top: 15px;
    position: relative;
}

.footer__container--logo{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15px;
    padding-left: 15px;
}

.footer__info{
    list-style-type: none;
    padding-bottom: 30px;
}

.footer__info--social{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    padding-bottom: 30px;
}

.footer-icon{
    margin: 5px;
}

.footer__copyright{
    position: absolute;
    bottom: 0;
    left: 45px;
}

.footer__logo{
    max-width: 200px;
}

.footer__separator{
    border-top: 1px solid rgb(134, 134, 134);
    margin: auto;
    width: 90%;
}

.footer a {
    text-decoration: none;
    color: #fff;
}

@media screen and (min-width: 768px){
    .footer__container{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 15px;
        position: relative;
    }

    .footer__logo{
        margin-bottom: 100px;
    }

    .footer__separator{
        display: none;
    }

    .footer__info::after{
        background-color: rgb(134, 134, 134);
        border-left: 1px solid rgb(134, 134, 134);
        content: '';
        display: inline-block;
        position: absolute;
        height: 175px;
        top: 20%;
        right: 25%;
    }

    .footer__info::before{
        background-color: rgb(134, 134, 134);
        border-left: 1px solid rgb(134, 134, 134);
        content: '';
        display: inline-block;
        position: absolute;
        height: 175px;
        top: 20%;
        left: 30%;
    }

    .footer__copyright{
        left: 55px;
    }
}
.header{
    background-image: url('https://firebasestorage.googleapis.com/v0/b/mytestproject-d6bc5.appspot.com/o/ItfestMotto_anosr.png?alt=media&token=9d599d16-935e-4558-ac01-15120dc5f9e6');
    width: 100%;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
}
.article{
    background-color: #004D69;
    opacity: 0.9;
    border-radius: 10px;
    color: #ffffff;
    width: 100%;
    position: relative;
    padding: 50px;
    padding-top: 10px;
}

.article__title{
    text-align: center;
    color: #fff;
}

.article__title::after{
    background-color: #0C2948;
    border: 1.5px solid #ffffff;
    border-radius: 30px;
    content: "";
    display: block;
    margin: 1rem;
}

.article__content{
    text-align: center;
    font-weight: 700;
}

@media screen and (min-width: 768px){
    .article{
        /* border: 1px solid rgb(134, 134, 134); */
        margin: 30px auto 0;
        width: 75%;
    }
}
.button {
	background-color:#fff;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#272767;
	font-family:Arial;
	font-size:17px;
	padding:12px 45px;
    text-align: center;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    transition: background-color 0.3s ease-in;
	width: 100%;
}
.button:hover {
	background-color:#0c2948;
	color: #fff;
}

@media screen and (min-width: 768px){
    .button{
	border-radius:8px;
	bottom: 15px;
	display:inline-block;
	cursor:pointer;
	color:#272767;
	font-family:Arial;
	font-size:17px;
	left: 0;
	margin: auto;
	padding:12px 45px;
	position: absolute;
	right: 0;
    text-align: center;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    transition: background-color 0.3s ease-in;
    max-width: 40%;
    }
}
.particles-background{
    background-color: black;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: -1;
}
.event__container{
    background-color: #004D69;
    opacity: 0.9;
    border-radius: 30px;
    color: #fff;
    padding: 15px;
    margin: 30px;
    max-width: 500px;
    min-height: 500px;
    position: relative;
    text-align: center;
}

.event__image__container{
    width: 100%;
    height: 200px;
}

.event__image{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}

.event__title::after{
    background-color: rgb(134, 134, 134);
    border: 1px solid rgb(134, 134, 134);
    content: '';
    display: block;
}

.event__details {
    text-align: justify;
}

.info {
    
}

@media screen and (min-width: 768px){
    .event__container{
        padding: 15px 15px 50px;
    }
}
.events__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.events__title {
    color: #ffffff;
    font-family: "Orbitron", sans-serif;
}

.container {
    text-align: center;
}
.profile__container{
    /* background-color: #fff; */
    border-radius: 5px;
    margin: 30px;
    min-width: 350px;
    padding: 30px;
}

.profile__container a {
    text-decoration: none;
}

.profile__image{
    border-radius: 50%;
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
}

.profile__name{
    color: rgb(200, 200, 200);
    text-align: center;
}

.profile__name::after{
    background-color: rgb(134, 134, 134);
    border: 1px solid rgb(134, 134, 134);
    content: '';
    display: block;
}

.profile__title{
    color: rgb(200, 200, 200);
    text-align: center;
}

.profile__phone{
    color: rgb(200, 200, 200);
    text-align: center;
}

.profile__mail{
    color: rgb(200, 200, 200);
    text-align: center;
}
.team__container{
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-around;
}

.container {
    padding-right: 10px;
    padding-left: 10px;
}

.logo {
    width: 150px;
}

.text {
    color: #fff;
    text-align: left;
}


@media screen and (min-width: 768px){
    .text {
        margin: 30px auto;
        max-width: 50%;
    }
}
.sponsors-container{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sponsor-image{
    margin: 50px;
    max-width: 180px;
    max-height: 250px;
}
.contact__container {
    background-attachment: fixed;
    /* background-image: url('../../../img/cosplay1.JPG'); */
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    padding-top: 10%;
}
.card__container {
	color: #ffffff;
	width: 50%;
	min-width: 15rem;
	margin: 50% auto 0;
	position: relative;
	text-align: center;
}

.card__title {
	color: rgb(255, 255, 255);
}

@media screen and (min-width: 768px) {
	.card__container {
		display: flex;
		text-align: left;
		justify-content: center;
		margin: auto;
		text-align: center;
	}

	.input__field {
		margin: 15px 15px;
	}

	.contact-data__container {
		display: flex;
		flex-direction: column;
		margin: 10px 50px 70px 50px;
	}
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
}

.app-container {
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 500px;
}

@media screen and (min-width: 768px){
    .app-container{
        padding-bottom: 500px;
    }
}


