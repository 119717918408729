.footer{
    bottom: 0;
    color: #fff;
    opacity: 0.9;
    left: 0;
    position: absolute;
    right: 0;
}

.footer__container{
    align-items: flex-start;
    background-color: #0C2948;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 200px;
    width: 100%;
    padding-top: 15px;
    position: relative;
}

.footer__container--logo{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15px;
    padding-left: 15px;
}

.footer__info{
    list-style-type: none;
    padding-bottom: 30px;
}

.footer__info--social{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    list-style-type: none;
    padding-bottom: 30px;
}

.footer-icon{
    margin: 5px;
}

.footer__copyright{
    position: absolute;
    bottom: 0;
    left: 45px;
}

.footer__logo{
    max-width: 200px;
}

.footer__separator{
    border-top: 1px solid rgb(134, 134, 134);
    margin: auto;
    width: 90%;
}

.footer a {
    text-decoration: none;
    color: #fff;
}

@media screen and (min-width: 768px){
    .footer__container{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 15px;
        position: relative;
    }

    .footer__logo{
        margin-bottom: 100px;
    }

    .footer__separator{
        display: none;
    }

    .footer__info::after{
        background-color: rgb(134, 134, 134);
        border-left: 1px solid rgb(134, 134, 134);
        content: '';
        display: inline-block;
        position: absolute;
        height: 175px;
        top: 20%;
        right: 25%;
    }

    .footer__info::before{
        background-color: rgb(134, 134, 134);
        border-left: 1px solid rgb(134, 134, 134);
        content: '';
        display: inline-block;
        position: absolute;
        height: 175px;
        top: 20%;
        left: 30%;
    }

    .footer__copyright{
        left: 55px;
    }
}