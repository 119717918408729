.team__container{
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-around;
}

.container {
    padding-right: 10px;
    padding-left: 10px;
}

.logo {
    width: 150px;
}

.text {
    color: #fff;
    text-align: left;
}


@media screen and (min-width: 768px){
    .text {
        margin: 30px auto;
        max-width: 50%;
    }
}