.button {
	background-color:#fff;
	border-radius:8px;
	display:inline-block;
	cursor:pointer;
	color:#272767;
	font-family:Arial;
	font-size:17px;
	padding:12px 45px;
    text-align: center;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    transition: background-color 0.3s ease-in;
	width: 100%;
}
.button:hover {
	background-color:#0c2948;
	color: #fff;
}

@media screen and (min-width: 768px){
    .button{
	border-radius:8px;
	bottom: 15px;
	display:inline-block;
	cursor:pointer;
	color:#272767;
	font-family:Arial;
	font-size:17px;
	left: 0;
	margin: auto;
	padding:12px 45px;
	position: absolute;
	right: 0;
    text-align: center;
	text-decoration:none;
    text-shadow:0px 1px 0px #2f6627;
    transition: background-color 0.3s ease-in;
    max-width: 40%;
    }
}