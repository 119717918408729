.card__container {
	color: #ffffff;
	width: 50%;
	min-width: 15rem;
	margin: 50% auto 0;
	position: relative;
	text-align: center;
}

.card__title {
	color: rgb(255, 255, 255);
}

@media screen and (min-width: 768px) {
	.card__container {
		display: flex;
		text-align: left;
		justify-content: center;
		margin: auto;
		text-align: center;
	}

	.input__field {
		margin: 15px 15px;
	}

	.contact-data__container {
		display: flex;
		flex-direction: column;
		margin: 10px 50px 70px 50px;
	}
}
