@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Rubik&display=swap');

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

body {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    padding: 0;
}

.app-container {
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 500px;
}

@media screen and (min-width: 768px){
    .app-container{
        padding-bottom: 500px;
    }
}

